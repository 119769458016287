.navigation-tree {
  width: 300px;
  min-width: 300px;
  background-color: $grey-background;
  padding: 25px 30px;
  margin-right: 25px;
  font-size: $small-font-size;
  color: $grey-dark;
  position: relative;

  @include breakpoint-mobile-down {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  &__title {
    color: $grey-dark;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    position: relative;
    z-index: 100 !important;
  }

  &__list {
    list-style-type: none;

    &.tree-level-1 {
      padding: 0;
      margin-bottom: 0;
    }

    &.tree-level-2 {
      padding: 1rem 0 0 1rem;
    }

    @include breakpoint-mobile-down {
      display: none;
    }
  }

  &__list-item {
    padding-bottom: 1rem;

    li:last-of-type {
      padding-bottom: 0;
    }
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  &__link {
    color: $grey-dark;

    &.active {
      font-weight: $font-weight-bold;
    }
  }

  .navigation__chevron-icon {
    cursor: pointer;
    position: absolute;
    right: 28px;
    top: 30px;
  }

  .js-dropdown-trigger {
    cursor: pointer;
  }

  @include breakpoint-mobile-up {
    .navigation__chevron-icon {
      display: none;
    }
  }

  &.js-dropdown-visible {
    .navigation-tree__list {
      display: block;
    }

    .navigation__chevron-icon {
      transform: rotate(180deg);
    }
  }
}
