.submit-wrapper,
.paypal-wrapper,
.recurring-wrapper {
  margin-top: 50px;
  width: 750px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
}

.paypal-text {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.payment__title {
  text-align: center;
  margin-bottom: 28px;
}

.payment img {
  max-width: 400px;
}

.payment__form-wrapper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 32px;
  @include breakpoint-mobile-down {
    flex-direction: column;
    align-items: center;
    gap: 18px;
  }
}

.payment__form {
  display: flex;
  flex-direction: column;
}

.payment__form p {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.payment__form ul {
  list-style: none;
  font-size: 14px;
  padding-left: 8px;
  margin-top: -12px;
}

.payment__form input {
  border: 1px solid $grey-border;
  padding: 5px 10px;
  color: $text-color;
  font-family: $basic-font-family;
  height: 35px;

  &:focus {
    border-color: $primary-color;
  }
}

.payment__form select {
  border: 1px solid $grey-border;
  padding: 5px 10px;
  color: $text-color;
  font-family: $basic-font-family;
  height: 35px;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url("../images/down-arrow.svg");
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: calc(100% - 16px);

  &:focus {
    border-color: $primary-color;
  }
}

.payment__button {
  @include button--primary;
  margin-top: 8px;
  cursor: pointer;
}

.payment-failure {
  text-align: center;
  max-width: 600px;
}

.payment-sucess {
  text-align: center;
}

.payment__description > p {
  margin-bottom: 20px;
}
