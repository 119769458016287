.responsive-object {
  /* set constant aspect ratio, padding-bottom is defined inline */
  height: 0;
  width: 100%;

  position: relative;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.fixed-size-object {
  /* center */
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
