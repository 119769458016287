.navbar {
  .container {
    min-height: 108px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    padding-right: 10px;
    a:hover {
      text-decoration: none;
    }
    img {
      height: 85px;
    }

    @include breakpoint-tablet-down {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 90px);

    @include breakpoint-tablet-down {
      width: 100%;
    }
  }

  &__top-row {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;

    @include breakpoint-tablet-down {
      display: block;
    }
  }

  &__bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  &__menu {
    width: calc(100% - 117px);
    padding-left: 1.25rem;

    @include breakpoint-tablet-down {
      display: none;
    }
  }

  &__languages {
    margin-left: 20px;
    display: flex;
    align-items: center;

    form {
      display: flex;
    }

    @include breakpoint-tablet-down {
      margin: 10px 0 0 0;
    }
  }
}

.active-lang {
  font-weight: $font-weight-bold;
  color: $primary-color;
}

.language-button {
  border-width: 0;
  background-color: transparent;
  font-family: $basic-font-family;
  font-size: $small-font-size;
  color: $primary-color;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 80px;

  span {
    padding-right: 5px;
  }

  @include breakpoint-tablet-down {
    min-width: auto;
    span {
      display: none;
    }
  }
}

.language-icon {
  width: 24px;
  height: 24px;
  display: inline-block;

  img {
    width: 24px;
    height: 24px;
  }
}

.sign-language-icon {
  margin: 0;
  margin-left: 5px;
  min-width: 24px;
}

.search-form {
  display: flex;
  align-content: center;

  &__input {
    @extend .input;

    @include breakpoint-tablet-down {
      max-width: 150px;
    }
  }

  &__button {
    border-width: 0;
    background-color: transparent;
    min-width: 30px;

    @include breakpoint-tablet-down {
      padding: 0;
    }
  }
}
