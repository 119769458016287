/* stylelint-disable no-descending-specificity */

// contrast buttons in navbar
.contrast-buttons {
  width: 100%;
  margin: 0 0 10px;

  @include breakpoint-tablet-up {
    margin: 0 15px 0 0;
  }
}

.button__contrast-normal,
.button__contrast-wb,
.button__contrast-yb,
.button__contrast-by {
  border: 2px solid black;
  padding: 8px 11px;
  text-align: center;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  cursor: pointer;
}

.button__contrast-normal {
  background-color: white;
  color: black;
}

.button__contrast-wb {
  background-color: black;
  color: white;
}

.button__contrast-yb {
  background-color: black;
  color: yellow;
}

.button__contrast-by {
  background-color: yellow;
  color: black;
}

.contrast-wb,
.contrast-yb {
  .button__contrast-normal,
  .button__contrast-wb,
  .button__contrast-yb {
    border: 2px solid white;
  }
  .button__contrast-by {
    border: 2px solid yellow;
  }
}

// contrast vesion: white on black
.contrast-wb {
  background-color: black;

  body,
  .footer,
  .footer__copyright,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .block-rich_text h2:first-of-type,
  .small-article__date,
  .small-article__lead,
  .small-article__lead *,
  .mobile-menu__container .menu__link {
    color: white;
  }

  .footer,
  .submenu,
  .navigation-tree,
  .search-form__input,
  .mobile-menu__container {
    background-color: black;
  }

  .search-form__input {
    border: 2px solid white;
    color: white;
  }

  .navbar__logo,
  .footer__logo {
    img {
      background-color: white;
    }
  }

  a,
  .link,
  .language-button,
  .read-more {
    color: white;
    text-decoration: underline;
  }

  .menu__link:hover,
  .menu__link.active {
    color: white;
    text-decoration: underline;
    font-weight: bold;
  }

  .read-more {
    &::after {
      content: "";
      display: inline-block;
      margin-left: 5px;
      height: 12px;
      width: 20px;
      background: url("../images/icon-arrow-right-white.svg") no-repeat;
    }
  }
  .read-more-icon {
    display: none;
  }

  .mobile-menu__icon {
    background: url("../images/icon-menu-white.png") no-repeat;
    background-size: contain;
  }

  .button--primary {
    background-color: black;
    border-color: black;

    &:hover {
      color: black;
      background-color: white;
    }
  }

  .button--secondary {
    color: black;
    border-color: black;

    &:hover {
      background-color: black;
      color: white;
    }
  }

  .page-item__content {
    background-color: black;
  }
}

// contrast version: yellow on black
.contrast-yb {
  background-color: black;

  body,
  .footer,
  .footer__copyright,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .block-rich_text h2:first-of-type,
  .small-article__date,
  .small-article__lead,
  .small-article__lead *,
  .carousel__content,
  .mobile-menu__container .menu__link {
    color: yellow;
  }

  .footer,
  .submenu,
  .navigation-tree,
  .search-form__input,
  .mobile-menu__container {
    background-color: black;
  }

  .search-form__input {
    border: 2px solid yellow;
    color: yellow;
  }

  .navbar__logo,
  .footer__logo {
    img {
      background-color: yellow;
    }
  }

  a,
  .link,
  .language-button,
  .read-more {
    color: yellow;
    text-decoration: underline;
  }

  .menu__link:hover,
  .menu__link.active {
    color: yellow;
    text-decoration: underline;
    font-weight: bold;
  }

  .read-more {
    &::after {
      content: "";
      display: inline-block;
      margin-left: 5px;
      height: 12px;
      width: 20px;
      background: url("../images/icon-arrow-right-yellow.svg") no-repeat;
    }
  }
  .read-more-icon {
    display: none;
  }

  .mobile-menu__icon {
    background: url("../images/icon-menu-yellow.png") no-repeat;
    background-size: contain;
  }

  .button--primary {
    background-color: black;
    border-color: black;

    &:hover {
      color: black;
      background-color: yellow;
    }
  }

  .button--secondary {
    color: black;
    border-color: black;

    &:hover {
      background-color: black;
      color: yellow;
    }
  }

  .page-item__content {
    background-color: black;
  }
}

// contrast version: black on yellow
.contrast-by {
  background-color: yellow;

  body,
  .footer,
  .footer__copyright,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .block-rich_text h2:first-of-type,
  .small-article__date,
  .small-article__lead,
  .small-article__lead *,
  .carousel__content,
  .mobile-menu__container .menu__link {
    color: black;
  }

  .footer,
  .submenu,
  .navigation-tree,
  .search-form__input,
  .mobile-menu__container {
    background-color: yellow;
  }

  .search-form__input {
    border: 2px solid black;
    color: black;
  }

  .navbar__logo,
  .footer__logo {
    img {
      background-color: yellow;
    }
  }

  a,
  .link,
  .language-button,
  .read-more {
    color: black;
    text-decoration: underline;
  }

  .menu__link:hover,
  .menu__link.active {
    color: black;
    text-decoration: underline;
    font-weight: bold;
  }

  .button--primary {
    background-color: yellow;
    border-color: yellow;

    &:hover {
      color: yellow;
      background-color: black;
    }
  }

  .button--secondary {
    color: black;
    border-color: black;

    &:hover {
      background-color: yellow;
      color: black;
    }
  }

  .page-item__content {
    background-color: yellow;
  }
}

/* stylelint-enable no-descending-specificity */
