.gallery {
  margin-bottom: 50px;
}

.gallery__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;

  @include breakpoint-tablet-down {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

.gallery__item {
  max-height: 175px;
}
