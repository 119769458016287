$mobile-width: 768px;
$mobile-narrow-width: 500px;
$tablet-width: 992px;
$screen-large-width: 1150px;

@mixin breakpoint-mobile-down {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin breakpoint-mobile-narrow-down {
  @media (max-width: #{$mobile-narrow-width}) {
    @content;
  }
}

@mixin breakpoint-mobile-up {
  @media (min-width: #{$mobile-width + 1px}) {
    @content;
  }
}

@mixin breakpoint-tablet-down {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin breakpoint-tablet-up {
  @media (min-width: #{$tablet-width + 1px}) {
    @content;
  }
}

@mixin breakpoint-tablet-only {
  @media (min-width: #{$mobile-width + 1px}) and (max-width: #{$screen-large-width - 1px}) {
    @content;
  }
}

@mixin breakpoint-large-up {
  @media (min-width: #{$screen-large-width + 1px}) {
    @content;
  }
}

@mixin breakpoint-large-down {
  @media (max-width: #{$screen-large-width}) {
    @content;
  }
}
