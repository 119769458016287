.featured-content {
  display: flex;
  justify-content: space-between;

  @include breakpoint-tablet-down {
    flex-direction: column;
  }
}

.carousel {
  position: relative;
  overflow: hidden;
  margin: 0 0 4rem;
  height: 400px;
  width: 60%;

  @include breakpoint-tablet-down {
    width: 100%;
    margin: 0 0 2rem;
  }
}

.carousel__siema {
  position: relative;
  height: 400px;
}

.carousel__controls {
  position: absolute;
  top: calc(50% - 12px);
  left: 0;
  padding: 0 0.25rem;
  z-index: 10;
  display: flex;
  width: 100%;
  justify-content: space-between;

  .carousel__next {
    margin: 0 0 0 1rem;
  }

  .carousel__prev,
  .carousel__next {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    color: white;
  }
}

.carousel__slide {
  margin: 0 auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;

  @include breakpoint-tablet-down {
    min-height: 350px;
    display: flex;
    justify-content: flex-start;
  }
}

.carousel__container {
  height: 400px;
  background-color: $overlay-color;

  @include breakpoint-tablet-down {
    width: 100%;
  }
}

.carousel__content {
  position: relative;
  z-index: 10;
  padding: 2rem;
  color: white;
  overflow: hidden;

  h1,
  h2,
  h3,
  a {
    color: white;
  }
}

.carousel-content__category {
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: $small-font-size;
  font-weight: $font-weight-semibold;
}

.carousel-content__title {
  font-size: $h2-font-size;
  line-height: 1.2;
  margin: 0 0 0.75rem;
}

.carousel-content__lead {
  margin-bottom: 1rem;
  font-size: $basic-font-size;
  * {
    font-size: $basic-font-size;
    font-weight: $font-weight-semibold;
  }
}

.carousel-content__date {
  font-size: $very-small-font-size;
  display: block;
  margin-bottom: 0.75rem;
}

.carousel-content__read-more {
  font-size: $basic-font-size;
  display: block;
  text-align: right;
}

.featured-richtext-content {
  width: 40%;
  padding-left: 2rem;

  @include breakpoint-tablet-down {
    width: 100%;
    padding-left: 0;
    margin-bottom: 50px;
  }
}

.block-rich_text {
  h2:first-of-type {
    margin-top: 0;
    color: $primary-color;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;
  }
}
