@import "_breakpoints.scss";
@import "_variables.scss";

// Buttons
@mixin button {
  border-width: 1px;
  border-style: solid;
  padding: 12px 18px;
  font-weight: $font-weight-regular;
  display: inline-block;
  text-align: center;
}

@mixin button--primary {
  @include button;
  color: white;
  background-color: $primary-color;
  border-color: $primary-color;
  &:hover {
    color: $primary-color;
    background-color: white;
  }
}

@mixin button--secondary {
  @include button;
  color: $primary-color;
  background-color: white;
  border-color: $primary-color;
  &:hover {
    background-color: $primary-color;
    color: white;
  }
}
