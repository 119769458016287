.richtext-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;

  padding-bottom: 50px;
  overflow: auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $basic-font-family;
    font-weight: $font-weight-bold;
    margin: 3rem 0 1.5rem;
    color: $grey-dark;
  }
  h1:first-of-type {
    margin-top: 0;
  }
  p,
  li {
    font-family: $basic-font-family;
    font-size: $basic-font-size;
    font-weight: $font-weight-regular;
  }
  p {
    margin-bottom: 0.5rem;
  }
  a {
    color: $primary-color;
  }
  img,
  .richtext-image {
    margin: 1rem 0;
    display: inline-block;
    height: auto;
    &.right {
      float: right;
      margin-left: 1rem;
    }
    &.left {
      float: left;
      margin-right: 1rem;
    }
    &.full-width {
      display: block;
      width: 100%;
      margin: 1rem auto;
    }
    &.right,
    &.left {
      max-width: 50%;
      @include breakpoint-tablet-down {
        max-width: 100%;
        width: 100%;
        float: none;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
