.page-category {
  margin-bottom: 50px;

  .read-more {
    display: inline-block;
    text-align: right;
  }
}

.page-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  @include breakpoint-tablet-down {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.page-item {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 25px;

  @include breakpoint-tablet-down {
    width: 100%;
  }

  &__image {
    height: 180px;
    min-height: 180px;
    img {
      height: 180px;
      min-height: 180px;
      width: 100%;
      object-fit: cover;
      border-radius: 4px 4px 0 0;
    }
  }

  &__content {
    background-color: $grey-background;
    padding: 1rem 1rem 3rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    height: calc(100% - 180px);
    border-radius: 0 0 4px 4px;
    position: relative;
  }

  &__date {
    font-size: $very-small-font-size;
    margin-bottom: 0.5rem;
  }

  &__title {
    font-size: $h3-font-size;
    margin: 0 0 0.75rem;
    color: $grey-dark;
  }

  &__lead {
    margin-bottom: 0.75rem;
    font-size: $small-font-size;
    * {
      font-size: $small-font-size;
    }
  }

  .read-more {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
  }
}
