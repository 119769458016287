.pagination {
  padding: 0;
  margin: 50px 0 25px;
  text-align: center;
  list-style-type: none;

  li {
    display: inline-block;
  }

  &__page-button {
    border-width: 0;
    background-color: transparent;
    padding: 5px 10px;
    font-weight: $font-weight-semibold;

    &:hover {
      cursor: pointer;
    }
  }

  &__current-page {
    font-weight: $font-weight-bold;
    color: $primary-color;
    font-size: $button-font-size;
    padding: 5px 10px;
  }
}
