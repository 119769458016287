.tippy-box {
  background: white;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    transform: translate(-50%, -100%);
  }
}

.tippy-arrow {
  display: none;
}
