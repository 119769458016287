.article {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;

  &__title {
    font-family: $basic-font-family;
    font-weight: $font-weight-bold;
    margin: 3rem 0 1.5rem;
    color: $grey-dark;
    font-size: $h1-font-size;
  }

  &__date {
    font-size: $small-font-size;
  }

  &__image {
    margin: 1rem 0;
    text-align: center;
    height: 400px;
    min-height: 400px;

    @include breakpoint-tablet-down {
      height: 300px;
      min-height: 300px;
    }

    img {
      display: inline-block;
      height: 400px;
      min-height: 400px;
      width: 100%;
      object-fit: cover;

      @include breakpoint-tablet-down {
        height: 300px;
        min-height: 300px;
      }
    }
  }

  &__lead {
    font-family: $basic-font-family;
    font-size: $lead-font-size;
    font-style: italic;
    font-weight: $font-weight-regular;
    line-height: 35px;
    letter-spacing: -0.20000000298023224px;
    margin-bottom: 1rem;

    @include breakpoint-tablet-down {
      line-height: 1.35;
    }
  }
}

.related-galleries,
.related-articles {
  margin-bottom: 50px;
  &__title {
    font-weight: $font-weight-semibold;
    font-size: $article-section-title-font-size;
    margin: 0 0 1rem;
  }
}

.related-articles {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;

    @include breakpoint-tablet-down {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }

  &__article {
    margin-bottom: 1rem;
    a {
      display: flex;
      flex-direction: column;
    }
  }
  &__article-image {
    text-align: center;

    img {
      height: 180px;
      display: inline-block;
      width: 100%;
      object-fit: cover;
    }
  }
  &__article-title {
    color: $text-color;
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;
    line-height: 1.2;
    margin-bottom: 0;
  }
}
