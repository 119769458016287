// containers
$container-width: 1100px;
$narrow-container-width: 750px;

// colors
$primary-color: #7a1c1c;
$text-color: #737b7d;
$grey-light: #c4c4c4;
$grey-border: #e0e0e0;
$grey-background: #fbfbfb;
$grey-dark: #373f41;
$error-color: #cf1313;
$overlay-color: #00000050;

// typography
$basic-font-family: "Mulish", sans-serif;

$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$basic-font-size: 1rem; // 16px
$h1-font-size: 2.2rem;
$h2-font-size: 1.76rem;
$h3-font-size: 1.125rem; // 18px
$article-section-title-font-size: 1.5625rem; // 25px
$lead-font-size: 1.125rem; // 18px
$button-font-size: 0.875rem; // 14px
$small-font-size: 0.875rem; // 14px
$very-small-font-size: 0.75rem; // 12px
