.tiles-section {
  margin-bottom: 50px;

  &__title {
    color: $primary-color;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;
  }
}

.featured-article {
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  @include breakpoint-tablet-down {
    flex-direction: column;
  }

  &__image,
  &__video {
    width: 55%;
    min-height: 320px;
    max-height: 360px;

    img {
      min-height: 320px;
      max-height: 360px;
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }

    iframe {
      min-height: 320px;
      max-height: 360px;
      width: 100%;
      max-width: 100%;
    }

    @include breakpoint-tablet-down {
      width: 100%;
    }
  }

  &__content {
    width: 40%;

    @include breakpoint-tablet-down {
      width: 100%;
      margin-top: 1rem;
    }
  }

  &__title {
    font-size: $h2-font-size;
    margin: 0 0 0.75rem;
  }

  &__date {
    font-size: $very-small-font-size;
    display: block;
    margin-bottom: 0.75rem;
  }

  &__lead {
    margin-bottom: 1rem;
    * {
      font-size: $small-font-size;
      font-weight: $font-weight-semibold;
    }
  }
}

.small-articles-list,
.embed-tiles-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  @include breakpoint-tablet-down {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.embed-tiles-list {
  @include breakpoint-tablet-down {
    display: block;
  }
}

.small-article,
.embed-tile {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 25px;

  @include breakpoint-tablet-down {
    width: 100%;
  }

  &__image,
  &__video {
    height: 200px;
    min-height: 200px;

    img {
      height: 200px;
      min-height: 200px;
      width: 100%;
      object-fit: cover;
    }

    iframe {
      height: 200px;
      min-height: 200px;
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 200px);
    padding-top: 1rem;
  }

  &__title {
    font-size: $h3-font-size;
    margin: 0 0 0.75rem;
  }

  &__date {
    font-size: $very-small-font-size;
    margin-bottom: 0.75rem;
    color: $text-color;
    display: block;
  }

  &__lead {
    color: $text-color;
    font-size: $basic-font-size;
    * {
      color: $text-color !important;
      font-weight: $font-weight-semibold;
      font-size: $basic-font-size;
      margin: 0;
    }
  }

  .read-more {
    padding-top: 1rem;
    font-size: $small-font-size;
  }
}

.embed-tile__content {
  font-size: $small-font-size;
}

.featured-embed-tile {
  grid-column: 1 / span 3;
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  @include breakpoint-tablet-down {
    flex-direction: column;
  }

  .embed-tile__video {
    width: 55%;
    min-height: 330px;

    iframe {
      min-height: 330px;
      width: 100%;
      max-width: 100%;
    }

    @include breakpoint-tablet-down {
      width: 100%;
    }
  }

  .embed-tile__content {
    width: 40%;
    * {
      font-size: $small-font-size;
      font-weight: $font-weight-semibold;
    }

    h2,
    h3 {
      font-size: $h2-font-size;
      margin-top: 0;
      line-height: 1.1;
    }

    @include breakpoint-tablet-down {
      width: 100%;
      margin-top: 1rem;
    }
  }
}
