@font-face {
  font-family: "Mulish";
  src: url("../fonts/Mulish-Bold.ttf") format("truetype");
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: url("../fonts/Mulish-Regular.ttf") format("truetype");
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: url("../fonts/Mulish-SemiBold.ttf") format("truetype");
  font-weight: $font-weight-semibold;
  font-style: normal;
}
