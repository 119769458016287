* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 100%;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

body {
  padding: 0;
  margin: 0;
  color: $text-color;
  line-height: 1.45;
  font-family: $basic-font-family;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  position: relative;
}

.container {
  width: $container-width;
  margin: 0 auto;
  padding-top: 80px;

  @include breakpoint-large-down {
    width: 100%;
    padding: 40px 20px 0;
  }
}

.container-with-navigation-tree {
  display: flex;

  @include breakpoint-mobile-down {
    flex-direction: column;
  }
}

img {
  max-width: 100%;
}

p {
  font-weight: $font-weight-regular;
  margin: 0;
}

a {
  text-decoration: none;
  color: $primary-color;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.link {
  color: $primary-color;
  font-weight: $font-weight-semibold;
  border-width: 0;
  padding: 0.75rem 0.5rem;
  background-color: transparent;
  display: inline-block;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.read-more {
  font-weight: $font-weight-semibold;
}

.read-more-icon {
  height: 12px;
  padding-left: 5px;
}

// headers
h1,
.h1 {
  font-size: $h1-font-size;
  font-weight: $font-weight-bold;
  color: $grey-dark;

  @include breakpoint-tablet-down {
    font-size: 2.25rem;
    line-height: 1.25;
  }
}

h2,
.h2 {
  font-size: $h2-font-size;
  font-weight: $font-weight-semibold;
  color: $grey-dark;
}

h3,
.h3 {
  font-size: $h3-font-size;
  font-weight: $font-weight-semibold;
  color: $grey-dark;
}

// disabled linters because they can't cope with specificity in
// styles for tags inside .invisible-link and .invisible-link
// inside tags - endless linter errors ocurred
/* stylelint-disable no-descending-specificity */
.invisible-link {
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }

  p,
  div,
  span {
    color: $text-color;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $grey-dark;
  }
}

p,
div,
span {
  .invisible-link {
    color: $text-color;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .invisible-link {
    color: $grey-dark;
  }
}
/* stylelint-enable no-descending-specificity */

// buttons

.button {
  @include button;

  &--primary {
    @include button--primary;
  }

  &--secondary {
    @include button--secondary;
  }

  &:hover {
    cursor: pointer;
  }
}

// forms

.input {
  border: 1px solid $grey-border;
  padding: 5px 10px;
  color: $text-color;
  font-family: $basic-font-family;
  height: 35px;

  &:focus {
    border-color: $primary-color;
  }
}

.label {
  font-size: $small-font-size;
  font-weight: $font-weight-bold;
}

.scroll-top {
  position: absolute;
  top: 120vh;
  bottom: 2rem;
  right: 2rem;
}

.scroll-top__link {
  position: sticky;
  top: 90vh;
  display: inline-block;
  border: 2px solid $primary-color;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: url("../images/icon-arrow-right.svg") no-repeat;
  background-color: white;
  background-position: center;
  transform: rotate(270deg);
  cursor: pointer;
  transition: 1s;
}
