.breadcrumbs {
  padding: 0;

  li {
    display: inline;
  }

  li + li:before {
    padding: 8px;
    content: "»";
  }
}
