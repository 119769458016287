.footer {
  background-color: $grey-background;
  color: $grey-dark;
  padding: 36px 0;
  flex-shrink: 0;

  &__content {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;

    a {
      color: $text-color;
      text-decoration: underline;
    }

    @include breakpoint-mobile-down {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__content-left,
  &__content-right {
    max-width: 49%;

    @include breakpoint-mobile-down {
      max-width: 100%;
    }
  }

  &__content-right {
    text-align: right;

    @include breakpoint-mobile-down {
      padding-top: 1rem;
      text-align: left;
    }
  }

  &__top-row,
  &__bottom-row {
    display: flex;
    justify-content: space-between;

    @include breakpoint-mobile-down {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__logo {
    padding-right: 1rem;

    @include breakpoint-mobile-down {
      padding-right: 0;
      padding-bottom: 1rem;
      width: 100%;
    }

    p {
      font-size: $small-font-size;
    }

    img {
      height: 85px;
    }
  }

  &__partners {
    padding-left: 1rem;

    @include breakpoint-mobile-down {
      padding-left: 0;
      width: 100%;
    }
  }

  &__partners-logo-link {
    display: inline-block;
    padding-left: 0.5rem;
  }

  &__bottom-row {
    padding-top: 30px;
  }

  &__copyright {
    color: $grey-light;
    text-align: right;
  }

  &__social-media {
    display: flex;
  }

  &__social-media-link {
    .bip-icon {
      max-width: 130px;
      padding: 8px 0 0 8px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}
